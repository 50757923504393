<template>
	<div class="icons">
		<fa
			class="success"
			icon="check-circle"
			v-if="item.checkedStatus == 'Safe for use'"
		/>
		<fa
			class="warning"
			icon="flag"
			v-else-if="item.checkedStatus == 'Time-critical issue'"
		/>
		<fa
			class="danger"
			icon="exclamation-triangle"
			v-else-if="item.checkedStatus == 'Do not use'"
		/>
		<fa
			class="muted"
			icon="search"
			v-else-if="item.checkedStatus == 'Missing'"
		/>
		<fa
			class="muted"
			icon="question-circle"
			v-else-if="item.checkedStatus == 'Unchecked'"
		/>
		<fa class="muted" icon="question-circle" v-else="!item.checkedStatus" />
		<router-link
			v-if="canEdit"
			:to="{
				name: 'EquipmentDetail',
				params: {
					id: item.id,
				},
			}"
			class="muted"
		>
			<fa icon="pen" />
		</router-link>
	</div>
</template>

<script>
import { ref, defineComponent } from "vue";

export default defineComponent({
	props: {
		item: {
			type: Object,
			required: true,
		},
		canEdit: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	setup(props) {
		const item = ref(props.item);
		const canEdit = ref(props.canEdit);

		console.log("Can Edit Right", canEdit);

		return {
			item,
			canEdit,
		};
	},
});
</script>
<style lang="scss" scoped>
.icons {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2em;
	font-size: 1.2em;
}
</style>
